import * as React from 'react';
import List from '@mui/joy/List';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import DoneIcon from '@mui/icons-material/Done';
import FormControl from "@mui/joy/FormControl";
import CreateBoard from "../CreateBoard";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DeleteBoard from "../DeleteBoard";

export default function Navigation({boards, activeBoard, setActiveBoard, handleSnackbarOpen}) {
    const [addBoard, setAddBoard] = React.useState(false);
    const [newBoardData, setNewBoardData] = React.useState({name: ''});
    const createBoard = CreateBoard();
    const deleteBoard = DeleteBoard();

    const handleCreate = () => {
        createBoard.mutate(newBoardData);
        setAddBoard(false);
    }

    const handleDelete = (id) => {
        deleteBoard.mutate(id);
        handleSnackbarOpen()
    }

    return (
        <List
            size="sm"
            sx={{'--ListItem-radius': 'var(--joy-radius-sm)', '--List-gap': '4px'}}
        >
            <ListItem nested>
                <ListSubheader
                    sx={{letterSpacing: '2px', fontWeight: '800', display: "flex", justifyContent: "space-between"}}>
                    Boards
                    <ListItem sx={{justifyContent: "center"}}>
                        <Button onClick={() => setAddBoard(!addBoard)} variant="soft">
                            <ListItemContent fontSize="x-small">
                                +
                            </ListItemContent>
                        </Button>
                    </ListItem>
                </ListSubheader>
                <List
                    aria-labelledby="nav-list-browse"
                    sx={{
                        '& .JoyListItemButton-root': {p: '8px'},
                    }}
                >
                    {boards && boards.map((board) =>
                        <ListItem key={board.id}>
                            <ListItemButton selected={activeBoard?.id === board.id}
                                            onClick={() => setActiveBoard(board)}>
                                <ListItemDecorator>
                                    <DashboardIcon fontSize="small"/>
                                </ListItemDecorator>
                                <ListItemContent>{board.name}</ListItemContent>
                                <Button size="sm"
                                        variant="plain"
                                        color="danger"
                                        onClick={() => handleDelete(board.id)}>
                                    <DeleteRoundedIcon/>
                                </Button>
                            </ListItemButton>
                        </ListItem>
                    )}
                    {addBoard && (
                        <ListItem style={{padding: "0px"}}>
                            <FormControl>
                                <Input onChange={(e) => setNewBoardData({name: e.target.value})}/>
                            </FormControl>
                            <Button onClick={(event) => handleCreate()}>
                                <DoneIcon/>
                            </Button>
                        </ListItem>
                    )
                    }
                </List>
            </ListItem>
        </List>
    );
}