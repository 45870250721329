import {
    Breadcrumbs,
    Card,
    CardActions,
    FormHelperText,
    Grid,
    Tab,
    tabClasses,
    TabList,
    TabPanel,
    Tabs,
    Textarea
} from "@mui/joy";
import {useNavigate, useParams} from "react-router-dom";
import Link from "@mui/joy/Link";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import CardContent from "@mui/joy/CardContent";
import {ImageList, ImageListItem} from '@mui/material';
import useHouse from "../useHouse.js";
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import BedIcon from '@mui/icons-material/Bed';
import BathtubIcon from '@mui/icons-material/Bathtub';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import Divider from "@mui/joy/Divider";
import EditorToolbar from "../components/EditorToolbar.tsx";
import CardOverflow from "@mui/joy/CardOverflow";
import Button from "@mui/joy/Button";

export default function HousePage() {
    const {houseId} = useParams();
    console.log("House ID: ", houseId);
    const navigate = useNavigate();
    const navigateToDashboard = () => {
        navigate("/dashboard");
    }
    const {data: house, isLoading, error} = useHouse(houseId);
    console.log("House Data: ", house);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <Box sx={{width: '960px', mx: 'auto'}}>
            <Box>
                <Breadcrumbs size="sm" aria-label="breadcrumbs" separator={<ChevronRightRoundedIcon fontSize="sm"/>}>
                    <Link underline="none" color="neutral" onClick={() => navigateToDashboard()}>
                        <HomeRoundedIcon/>
                    </Link>
                    <Link underline="none">
                        <Typography level="title-sm" color="primary">
                            House
                        </Typography>
                    </Link>
                </Breadcrumbs>
            </Box>
            <Stack
                spacing={4}
                sx={{
                    display: 'flex',
                    maxWidth: '800px',
                    mx: 'auto',
                    px: {xs: 2, md: 6},
                    py: {xs: 2, md: 3},
                }}
            >
                <Card variant="outlined">
                    <ImageList cols={3} rowHeight={164}>
                        {house?.photos.map((item) => (
                            <ImageListItem key={item.img}>
                                <img
                                    srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                    src={`${item}?w=164&h=164&fit=crop&auto=format`}
                                    alt={"house"}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                    <CardContent>
                        <Link
                            level="h1"
                            underline="none"
                            rel="noopener"
                            target="_blank"
                            color="neutral"
                            href={house.link}
                        >
                            {house.title}</Link>
                        <Typography level="h5">{house.address}</Typography>
                    </CardContent>
                    <Tabs defaultValue={0} sx={{bgcolor: 'transparent'}}>
                        <TabList
                            size="sm"
                            sx={{
                                justifyContent: 'space-around',
                                [`&& .${tabClasses.root}`]: {
                                    fontWeight: '600',
                                    flex: 'initial',
                                    color: 'text.tertiary',
                                    [`&.${tabClasses.selected}`]: {
                                        bgcolor: 'transparent',
                                        color: 'text.primary',
                                        '&::after': {
                                            height: '2px',
                                            bgcolor: 'primary.500',
                                        },
                                    },
                                },
                            }}
                        >
                            <Tab sx={{borderRadius: '6px 6px 0 0'}} indicatorInset value={0}>
                                Description
                            </Tab>
                            <Tab sx={{borderRadius: '6px 6px 0 0'}} indicatorInset value={1}>
                                Notes
                            </Tab>
                            <Tab sx={{borderRadius: '6px 6px 0 0'}} indicatorInset value={2}>
                                Events
                            </Tab>
                            <Tab sx={{borderRadius: '6px 6px 0 0'}} indicatorInset value={3}>
                                Insights (Coming Soon)
                            </Tab>
                        </TabList>
                        <TabPanel value={0}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={2} sx={{my: 2}}>
                                        <Grid item xs={6} sm={3}>
                                            <Box display="flex" alignItems="center">
                                                <CurrencyPoundIcon color="primary" sx={{fontSize: "32px"}}/>
                                                <Typography level="body" sx={{ml: 1}}>{house.price}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Box display="flex" alignItems="center">
                                                <BedIcon color="primary" sx={{fontSize: "32px"}}/>
                                                <Typography level="body" sx={{ml: 1}}>{house.bedrooms}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Box display="flex" alignItems="center">
                                                <BathtubIcon color="primary" sx={{fontSize: "32px"}}/>
                                                <Typography level="body" sx={{ml: 1}}>{house.bathrooms}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Box display="flex" alignItems="center">
                                                <SquareFootIcon color="primary" sx={{fontSize: "32px"}}/>
                                                <Typography level="body" sx={{ml: 1}}>{house.floorSpaceFt} ft²
                                                    / {house.floorSpaceSqm} m²</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{my: 2}}>
                                        <Grid item xs={6} sm={4}>
                                            <Typography level="body" level="title-md">Added
                                                On: {house.addedOn}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <Typography level="body" level="title-md">Property
                                                Type: {house.propertyType}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <Typography level="body"
                                                        level="title-md">Status: {house.status.charAt(0).toUpperCase()
                                                + house.status.slice(1)}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Typography level="body" level="title-md">Key Features:</Typography>
                                    <Typography>{house.keyFeatures}</Typography>
                                    <Typography level="body" level="title-md">Description:</Typography>
                                    <Typography level="body">{house.propertyDescription}</Typography>
                                </CardContent>
                            </Card>
                        </TabPanel>
                        <TabPanel value={1}>
                            <Card>
                                <Box sx={{mb: 1}}>
                                    <Typography level="title-md">Notes</Typography>
                                    <Typography level="body-sm">
                                        Add any notes or comments about this property.
                                    </Typography>
                                </Box>
                                <Divider/>
                                <Stack spacing={2} sx={{my: 1}}>
                                    <EditorToolbar/>
                                    <Textarea
                                        size="sm"
                                        minRows={4}
                                        sx={{mt: 1.5}}
                                        defaultValue="Great renovation opportunity."
                                    />
                                    <FormHelperText sx={{mt: 0.75, fontSize: 'xs'}}>
                                        275 characters left
                                    </FormHelperText>
                                </Stack>
                                <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                                    <CardActions sx={{alignSelf: 'flex-end', pt: 2}}>
                                        <Button size="sm" variant="outlined" color="neutral">
                                            Cancel
                                        </Button>
                                        <Button size="sm" variant="solid">
                                            Save
                                        </Button>
                                    </CardActions>
                                </CardOverflow>
                            </Card>
                        </TabPanel>
                    </Tabs>
                </Card>
            </Stack>
        </Box>
    )
        ;
}