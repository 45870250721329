import Navbar from "./Navbar";
import Layout from './Layout';
import Navigation from "./Navigation";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import {v4 as uuidv4} from 'uuid';
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import BoardList from "./BoardList";
import {
    closestCorners,
    defaultDropAnimation,
    DndContext,
    DragEndEvent,
    DragOverEvent,
    DragOverlay,
    DragStartEvent,
    DropAnimation,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import {arrayMove, sortableKeyboardCoordinates} from '@dnd-kit/sortable';
import HouseCard from "./HouseCard";
import UpdateHouse from "../UpdateHouse";
import {Card, ListItem, Snackbar} from "@mui/joy";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CardOverflow from "@mui/joy/CardOverflow";
import AspectRatio from "@mui/joy/AspectRatio";
import Boards from "../Boards";

const getHouseById = (houses, id) => {
    return houses.find((house) => house.id === id);
}

const findBoardSectionContainer = (boardSections, id) => {
    if (id in boardSections) {
        return id;
    }

    return Object.keys(boardSections).find((key) => {
        return boardSections[key].items.some((item) => item.id === id);
    });
}

export default function Dashboard() {
    // Your state declarations
    const {login, isAuthenticated} = useKindeAuth();
    const {data: boards} = Boards();
    const user = useKindeAuth();
    const [columns, setColumns] = useState({});
    const [activeTaskId, setActiveTaskId] = useState(null);
    const [activeBoard, setActiveBoard] = useState(null);
    const [houses, setHouses] = useState(null)
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const columnIdsRef = useRef({
        saved: uuidv4(),
        viewing: uuidv4(),
        offer: uuidv4(),
        rejected: uuidv4()
    });

    useEffect(() => {
        if (boards && boards.length > 0) {
            setActiveBoard((prevActiveBoard) => {
                // Check if the previous active board is still in the updated boards list
                const updatedActiveBoard = boards.find(board => board.id === prevActiveBoard?.id);
                return updatedActiveBoard || boards[0]; // Set to the previous active board or the first board if not found
            });
        } else {
            setActiveBoard(null);
        }
    }, [boards]);

    useEffect(() => {
        if (activeBoard) {
            setHouses(activeBoard.houses);
        }
    }, [activeBoard]);

    useEffect(() => {
        if (houses) {
            const newColumns = {
                [columnIdsRef.current.saved]: {status: 'Saved', items: filterHouses('saved')},
                [columnIdsRef.current.viewing]: {status: 'Viewing', items: filterHouses('viewing')},
                [columnIdsRef.current.offer]: {status: 'Offer', items: filterHouses('offer')},
                [columnIdsRef.current.rejected]: {status: 'Rejected', items: filterHouses('rejected')},
            };

            setColumns(newColumns);
        }
    }, [boards, houses, activeBoard]);

    function filterHouses(status) {
        return houses?.filter(house => house.status === status).sort((a, b) => a.position - b.position);
    }

    const handleDragStart = ({active}: DragStartEvent) => {
        setActiveTaskId(active.id);
    };

    const updateHouse = UpdateHouse();

    const handleDragOver = ({active, over}: DragOverEvent) => {
        // Find the containers
        const activeContainer = findBoardSectionContainer(
            columns,
            active.id
        )
        const overContainer = findBoardSectionContainer(
            columns,
            over?.id
        )


        if (
            !activeContainer ||
            !overContainer ||
            activeContainer === overContainer
        ) {
            return;
        }

        setColumns((boardSection) => {
            const activeItems = boardSection[activeContainer].items;
            const overItems = boardSection[overContainer].items;

            // Find the indexes for the items
            const activeIndex = activeItems.findIndex(
                (item) => item.id === active.id
            );
            const overIndex = overItems.findIndex((item) => item.id !== over?.id);

            return {
                ...boardSection,
                [activeContainer]: {
                    ...boardSection[activeContainer],
                    items: [...boardSection[activeContainer].items.filter(
                        (item) => item.id !== active.id
                    )]
                },
                [overContainer]: {
                    ...boardSection[overContainer],
                    items:
                        [...boardSection[overContainer].items.slice(0, overIndex),
                            columns[activeContainer].items[activeIndex],
                            ...boardSection[overContainer].items.slice(
                                overIndex,
                                boardSection[overContainer].items.length
                            )
                        ]
                },
            };
        });
    };

    const handleDragEnd = ({active, over}: DragEndEvent) => {
        const activeContainer = findBoardSectionContainer(
            columns,
            active.id
        );
        const overContainer = findBoardSectionContainer(
            columns,
            over?.id
        );

        if (
            !activeContainer ||
            !overContainer ||
            activeContainer !== overContainer
        ) {
            return;
        }

        const activeIndex = columns[activeContainer].items.findIndex(
            (task) => task.id === active.id
        );
        const activeItem = columns[activeContainer].items[activeIndex];
        const overIndex = columns[overContainer].items.findIndex(
            (task) => task.id === over?.id
        );


        if (activeIndex !== overIndex) {
            setColumns((boardSection) => ({
                ...boardSection,
                [overContainer]: {
                    ...boardSection[overContainer],
                    items: arrayMove(
                        boardSection[overContainer].items,
                        activeIndex,
                        overIndex)
                }
            }));
            updateHouse.mutate(
                {
                    ...activeItem,
                    position: overIndex + 1,
                    status: columns[overContainer].status.toLowerCase()
                });
        }

        setActiveTaskId(null);
    };


    const dropAnimation: DropAnimation = {
        ...defaultDropAnimation,
    };

    const house = activeTaskId ? getHouseById(houses, activeTaskId) : null;

    const [openSnackBar, setOpenSnackBar] = useState(false);

    const handleSnackbarOpen = () => {
        console.log("Snackbar opened in Dashboard");
        setOpenSnackBar(true);
    }

    if (!user || !isAuthenticated) {
        return (
            <Layout.Main sx={{height: '100vh', width: '100vw'}}>
                <Layout.Header>
                    <Navbar user={user}/>
                </Layout.Header>
                <Layout.Main style={{display: "flex", justifyContent: "center"}}>
                    <Box
                        height={500}
                        width={1000}
                        my={4}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        gap={4}
                        p={2}
                        sx={{}}
                    >
                        <div>
                            <Typography level='h1' variant='plain' style={{fontWeight: 'bold'}}>Please login to view
                                this page</Typography>
                        </div>
                        <div>
                            <Typography>
                                <Button variant="plain" onClick={login}>Sign in</Button>
                            </Typography>
                        </div>
                    </Box>
                </Layout.Main>
            </Layout.Main>
        )
    }

    return (
        <div>
            <Layout.Root sx={{height: '100vh', width: '100vw'}}>
                <Layout.Header>
                    <Navbar user={user}/>
                </Layout.Header>
                <Layout.SideNav>
                    <Navigation boards={boards} activeBoard={activeBoard} setActiveBoard={setActiveBoard}
                                handleSnackbarOpen={handleSnackbarOpen}/>
                </Layout.SideNav>
                {!activeBoard ? (
                    <Layout.Main sx={{height: '100%', width: "calc(100vw - 300px)"}}>
                        <Box
                            height="100%"
                            width="100%"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{}}
                        >
                            <div>
                                <Typography level='h1' variant='plain' style={{fontWeight: 'bold'}}>No boards
                                    found</Typography>
                            </div>
                            <div>
                                <Typography>
                                    <Typography level='h4' variant="plain" color="primary">Create a board to get
                                        started</Typography>
                                </Typography>
                            </div>
                        </Box>
                    </Layout.Main>
                ) : (
                    <Layout.Main sx={{width: "calc(100vw - 300px)", height: '100%'}}>
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCorners}
                            onDragStart={handleDragStart}
                            onDragOver={handleDragOver}
                            onDragEnd={handleDragEnd}
                        >
                            <Box sx={{
                                display: 'grid',
                                alignItems: 'flex-start',
                                justifyContent: 'space-between',
                                overflow: 'auto',
                                height: '100%',
                                bgcolor: 'background.surface',
                            }}>
                                {Object.entries(columns).map(([columnId, column]) => {
                                    return (
                                        <BoardList id={columnId} list={column} key={columnId} boardId={activeBoard.id}
                                                   handleSnackbarOpen={handleSnackbarOpen}
                                        />
                                    )
                                })}
                                <DragOverlay dropAnimation={dropAnimation}>
                                    {house ? (
                                        <ListItem sx={{alignSelf: 'center', padding: 'auto'}}>
                                            <Card variant="outlined" sx={{width: '280px'}}>
                                                <CardOverflow>
                                                    <AspectRatio ratio="2">
                                                        <img
                                                            src={house.photos[0]}
                                                            srcSet={house.photos[0]}
                                                            loading="lazy"
                                                            alt=""
                                                        />
                                                    </AspectRatio>
                                                    <HouseCard house={house} handleSnackbarOpen={handleSnackbarOpen}/>
                                                </CardOverflow>
                                            </Card>
                                        </ListItem>
                                    ) : null}
                                </DragOverlay>
                            </Box>
                        </DndContext>
                    </Layout.Main>
                )
                }
            </Layout.Root>
            <Snackbar
                color="danger"
                open={openSnackBar}
                onClose={() => setOpenSnackBar(false)}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                startDecorator={<CheckCircleRoundedIcon/>}
                endDecorator={
                    <Button
                        onClick={() => setOpenSnackBar(false)}
                        size="sm"
                        variant="soft"
                        color="neutral"
                    >
                        Dismiss
                    </Button>
                }
            >
                Deleted successfully
            </Snackbar>
        </div>
    );
}
